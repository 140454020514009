import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NativeAds,BannerAds,GridAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const Home = () => {
  
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      await new Promise(resolve => setTimeout(resolve, 50));
      const responseRdlist = await axios.get('/rdlist');
      if (responseRdlist.data.status === 200) {
        setData(responseRdlist.data.data);
      }

      const response = await axios.get('/home');
      if (response.data.status === 200) {
        setData1(response.data.data[0].result1);
        setData2(response.data.data[1].result2);
        setData3(response.data.data[2].result3);
        setData4(response.data.data[3].result4);
        setData5(response.data.data[4].result5);
      }
    }catch (error) {
      console.error('Error fetching Home data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  
  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return (
      <ErrorRetry title={'首页更新'} onClick={fetchData} />
    );
  }

  const rdlistData = async ()=>{
    await new Promise(resolve => setTimeout(resolve, 50));
    const responseRdlist = await axios.get('/rdlist');
    if (responseRdlist.data.status === 200) {
      setData(responseRdlist.data.data);
    }
  }

  const insertAdIntoData = (items) => {
    if (!items.length) return items;
    const randomIndex = Math.floor(Math.random() * (items.length + 1));
    return [
      ...items.slice(0, randomIndex),
        {"title":"itemAd"},
      ...items.slice(randomIndex),
    ];
  }
  const dataWithAd = insertAdIntoData(data);
  const data1WithAd = insertAdIntoData(data1);
  const data2WithAd = insertAdIntoData(data2);
  const data3WithAd = insertAdIntoData(data3);
  const data4WithAd = insertAdIntoData(data4);
  const data5WithAd = insertAdIntoData(data5);

  const dum = (n) => {
      window.location = '/z1.html';
 
  };
 
  return (
    <>
      <Meta title="首页-8X8X" description="欢迎来到3B，这里有最精彩的在线视频，每天更新永久免费" keywords="3B, 首页, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds/>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 flex justify-between items-center" style={{marginTop:'10px'}} onClick={rdlistData}>
         精选视频 <span style={{ marginLeft: '20px' }}>换一批视频</span>
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {dataWithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-2">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </Link>
            )}
          </div>
        ))}
      </div>
 
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/大陆/`}>大陆</Link> 最新上架
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data1WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 0 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/日韩/`}>日韩</Link> 最新上架
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data2WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/欧美/`}>欧美</Link> 最新上架
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data3WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/动漫/`}>动漫</Link> 最新上架
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data4WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/三级/`}>三级</Link> 最新上架
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data5WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(380, 235)}
                  errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                  width={380}
                  height={235}
                />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </Link>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
